import React from 'react';
import { TFunction, useTranslation } from '@wix/yoshi-flow-editor';
import { classes, st } from './PaymentSummary.st.css';
import Text, { TextType } from '../../Text/Text';
import { PaymentSummaryDataHook } from './dataHooks';
import {
  PaymentOption,
  ReservedPaymentOptionIds,
  ServicePaymentDetails,
} from '../../../../types/types';
import { Divider } from 'wix-ui-tpa/Divider';
import { TagName } from '../../../../utils/tagName.const';
import { PriceSummaryItem } from './PriceSummaryItem/PriceSummaryItem';
import { PaymentDetails } from '../PaymentDetails/PaymentDetails';
import { getContent } from '../../../../utils/content/content';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import settingsParams from '../../settingsParams';
import { CouponDetails } from '@wix/ambassador-checkout-server/types';
import { FormStatus } from '../../../../utils/state/initialStateFactory';
import { Spinner } from 'wix-ui-tpa/Spinner';

export type PaymentSummaryProps = {
  servicePayment: ServicePaymentDetails;
  selectedPaymentOption: PaymentOption;
  dateRegionalSettingsLocale: string;
  numberOfParticipants: number;
  appliedCoupon?: CouponDetails;
  status: FormStatus;
};

export const PaymentSummary: React.FC<PaymentSummaryProps> = ({
  servicePayment,
  selectedPaymentOption,
  dateRegionalSettingsLocale,
  numberOfParticipants,
  appliedCoupon,
  status,
}) => {
  const { t } = useTranslation();
  const settings = useSettings();
  const {
    price,
    priceText,
    currency,
    minCharge: deposit,
    totalPrice,
  } = servicePayment;

  const isPricingPlanSelected =
    !selectedPaymentOption.disabled &&
    ![
      ReservedPaymentOptionIds.SingleSession,
      ReservedPaymentOptionIds.BuyAPricingPlan,
    ].includes(selectedPaymentOption.id as ReservedPaymentOptionIds);

  const showSingleSessionSummary = (): boolean => {
    const serviceHasPrice = price > 0 || !!priceText;
    return (
      serviceHasPrice &&
      selectedPaymentOption.id === ReservedPaymentOptionIds.SingleSession
    );
  };

  const shouldShowPaymentSummary = () => {
    return showSingleSessionSummary() || isPricingPlanSelected;
  };

  const sectionTitle = getContent({
    settings,
    settingsParam: settingsParams.summaryPaymentSectionTitle,
  });

  const couponDiscount = Number(appliedCoupon?.couponDiscount) || 0;

  const isProcessingPaymentDetails =
    status === FormStatus.PROCESSING_PAYMENT_DETAILS;

  return shouldShowPaymentSummary() ? (
    <div
      className={st(classes.root, { isProcessingPaymentDetails })}
      data-hook={PaymentSummaryDataHook.Payment_Summary}
    >
      <Divider className={classes.divider} />
      <Text
        tagName={TagName.H3}
        type={TextType.Primary}
        className={classes.title}
        data-hook={PaymentSummaryDataHook.Title}
      >
        {sectionTitle}
      </Text>
      {showSingleSessionSummary() ? (
        <div className={classes.singleSession}>
          {isProcessingPaymentDetails ? (
            <div className={classes.spinner}>
              <Spinner
                isCentered
                diameter={24}
                data-hook={PaymentSummaryDataHook.Spinner}
              />
            </div>
          ) : null}
          <div className={classes.details}>
            {priceText ? (
              <div>
                <Text
                  type={TextType.Secondary}
                  data-hook={PaymentSummaryDataHook.Custom_Price}
                >
                  {priceText}
                </Text>
              </div>
            ) : null}
            {getPriceSummaryItems({
              numberOfParticipants,
              price,
              totalPrice,
              deposit,
              couponDiscount,
              t,
            }).map((summaryItem, index) => (
              <PriceSummaryItem
                key={index}
                type={summaryItem.type}
                label={summaryItem.label}
                price={summaryItem.amount}
                multiplier={summaryItem.multiplier}
                locale={dateRegionalSettingsLocale}
                currency={currency}
              />
            ))}
          </div>
        </div>
      ) : (
        <PaymentDetails paymentOption={selectedPaymentOption} />
      )}
    </div>
  ) : null;
};

const getPriceSummaryItems = ({
  numberOfParticipants,
  couponDiscount,
  price,
  totalPrice,
  deposit,
  t,
}: {
  numberOfParticipants: number;
  couponDiscount: number;
  price: number;
  deposit: number;
  t: TFunction;
  totalPrice?: number;
}) => {
  return [
    ...(numberOfParticipants > 1
      ? [
          {
            type: TextType.Secondary,
            label: t('app.payment.options.single-session.text'),
            amount: price,
            multiplier: numberOfParticipants,
          },
        ]
      : []),
    ...(couponDiscount
      ? [
          {
            type: TextType.Secondary,
            label: t('app.payment.summary.promo-code.text'),
            amount: -couponDiscount,
          },
        ]
      : []),
    ...(totalPrice
      ? [
          {
            type: TextType.Primary,
            label: t('app.payment.summary.total.text'),
            amount: totalPrice,
          },
        ]
      : []),
    ...(deposit
      ? [
          {
            type: TextType.Primary,
            label: t('app.payment.summary.deposit.text'),
            amount: deposit,
          },
        ]
      : []),
  ];
};
