import React from 'react';
import { TagName } from '../../../../utils/tagName.const';
import { PaymentSelectionHook } from './dataHooks';
import {
  useEnvironment,
  useTranslation,
  TFunction,
} from '@wix/yoshi-flow-editor';
import { classes, st } from './PaymentSelection.st.css';
import { useFormActions } from '../../Hooks/useFormActions';
import {
  PaymentOption,
  ReservedPaymentOptionIds,
} from '../../../../types/types';
import { PaymentOption as PaymentOptionComponent } from './PaymentOption/PaymentOption';
import {
  SectionMessage,
  SectionMessageType,
} from '../SectionMessage/SectionMessage';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { getContent } from '../../../../utils/content/content';
import settingsParams from '../../settingsParams';
import { SectionTitle } from '../SectionTitle/SectionTitle';

export type PaymentSelectionProps = {
  selectedPaymentOptionId: string;
  paymentOptions: PaymentOption[];
  numberOfParticipants: number;
};

export const PaymentSelection: React.FC<PaymentSelectionProps> = ({
  paymentOptions,
  selectedPaymentOptionId,
  numberOfParticipants,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const { setPaymentOption } = useFormActions();
  const shouldShowPaymentMethodSelection = paymentOptions.length > 1;

  const title = getContent({
    settings,
    settingsParam: settingsParams.selectPaymentMethodTitle,
  });

  const paymentOptionWarnings = getPaymentOptionWarnings({
    paymentOptions,
    numberOfParticipants,
    t,
  });

  return shouldShowPaymentMethodSelection ? (
    <fieldset
      className={st(classes.root, { isMobile })}
      data-hook={PaymentSelectionHook.Payment_Selection}
    >
      <SectionTitle
        label={title}
        data-hook={PaymentSelectionHook.Title}
        tagName={TagName.Legend}
      />
      {paymentOptionWarnings.map((sectionMessageText) => (
        <SectionMessage
          key={sectionMessageText}
          type={SectionMessageType.Alert}
          text={sectionMessageText}
        />
      ))}
      <div className={classes.paymentOptions}>
        {paymentOptions.map((paymentOption) => (
          <PaymentOptionComponent
            key={paymentOption.id!}
            id={paymentOption.id!}
            label={paymentOption.label!}
            selected={paymentOption.id === selectedPaymentOptionId}
            disabled={paymentOption.disabled!}
            suffix={paymentOption.suffix}
            validUntil={paymentOption.validUntil}
            onChange={setPaymentOption}
          />
        ))}
      </div>
    </fieldset>
  ) : null;
};

const getPaymentOptionWarnings = ({
  paymentOptions,
  numberOfParticipants,
  t,
}: {
  paymentOptions: PaymentOption[];
  numberOfParticipants: number;
  t: TFunction;
}) => {
  const paymentOptionWarnings: string[] = [];
  paymentOptions.forEach((paymentOption: PaymentOption) => {
    const pricingPlanOption =
      paymentOption.id !== ReservedPaymentOptionIds.BuyAPricingPlan &&
      paymentOption.id !== ReservedPaymentOptionIds.SingleSession;
    if (pricingPlanOption) {
      const isMembershipWithFewParticipants =
        !paymentOption.creditRemain && numberOfParticipants > 1;
      const isPaymentOptionWithoutEnoughCredit =
        Number(paymentOption?.creditRemain) < numberOfParticipants;
      if (isMembershipWithFewParticipants) {
        paymentOptionWarnings.push(
          t(
            'app.payment.warning.membership-plan-reduce-participans-number.text',
            {
              planName: paymentOption?.label,
            },
          ),
        );
      } else if (isPaymentOptionWithoutEnoughCredit) {
        paymentOptionWarnings.push(
          t(
            'app.payment.warning.not-enough-sessions-left-reduce-participans-number.text',
            { planName: paymentOption?.label },
          ),
        );
      }
    }
  });
  return paymentOptionWarnings;
};
