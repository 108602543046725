import { useContext } from 'react';
import {
  ISettingsContextValue,
  ISettingsResetAll,
  ISettingsSetter,
  IStylesContextValue,
  IStylesResetAll,
  IStylesSetParam,
  ISettingsValue,
  ISettingsParam,
} from '@wix/yoshi-flow-editor/tpa-settings';
import {
  useSettings,
  useStyles,
} from '@wix/yoshi-flow-editor/build/cjs/tpa-settings/react';
import { useBi } from '@wix/yoshi-flow-editor';
import {
  bookingsSettingsElementChanged,
  bookingsResetToOriginalDesignClick429,
} from '@wix/bi-logger-wixboost-users/v2';
import { SettingsTabsContext } from '../../SharedSettings/SettingsTabsContext';
import { widgetDefaults } from '../../../../utils/bi/consts';

export const useStylesAdapter = (): IStylesContextValue => {
  const styles: IStylesContextValue = useStyles();
  const logger: ReturnType<typeof useBi> = useBi();
  const { currentTabId } = useContext(SettingsTabsContext);

  const set: IStylesSetParam = (key, value) => {
    styles.set(key, value);
    sendSetBIEvent(logger, currentTabId!, key, value);
  };
  const resetAll: IStylesResetAll = (stylesToReset: any) => {
    styles.resetAll(stylesToReset);
    sendResetAllBIEvent(logger, currentTabId!, stylesToReset);
  };

  return {
    ...styles,
    set,
    resetAll,
  };
};

export const useSettingsAdapter = (): ISettingsContextValue => {
  const settings = useSettings();
  const logger: ReturnType<typeof useBi> = useBi();
  const { currentTabId } = useContext(SettingsTabsContext);

  const set: ISettingsSetter = (key: any, value: any) => {
    settings.set(key, value);
    sendSetBIEvent(logger, currentTabId!, key, value);
  };
  const resetAll: ISettingsResetAll = (settingsToReset: any) => {
    settings.resetAll(settingsToReset);
    sendResetAllBIEvent(logger, currentTabId!, settingsToReset);
  };

  return {
    ...settings,
    set,
    resetAll,
  } as ISettingsContextValue;
};

const sendSetBIEvent = (
  logger: ReturnType<typeof useBi>,
  currentTabId: string,
  param: any,
  value: ISettingsValue,
) => {
  logger.report(
    bookingsSettingsElementChanged({
      element: param.key,
      platformName: 'editor',
      tabName: currentTabId,
      selection: JSON.stringify(value),
      appName: widgetDefaults.pageName,
    }),
  );
};

const sendResetAllBIEvent = (
  logger: ReturnType<typeof useBi>,
  currentTabId: string,
  stylesToReset: ISettingsParam,
) => {
  const keys = Object.keys(stylesToReset);
  logger.report(
    bookingsResetToOriginalDesignClick429({
      tabName: currentTabId,
      items: JSON.stringify(keys),
    }),
  );
};
